import OrganShooterJSON from "../public/assets/temp/aseprite/Organ_Shooter.json";

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//    Enums / Types
///////////////////////////////////////////////////////////////////////////////////////////////////////////

/* Metronome Intervals */

export enum MetronomeIntervals {
  INTERVAL_BAR_025 = "INTERVAL_BAR_025",
  INTERVAL_BAR_05 = "INTERVAL_BAR_05",
  INTERVAL_BAR_OFFSET_1 = "INTERVAL_BAR_OFFSET_1",
  INTERVAL_BAR_1 = "INTERVAL_BAR_1",
  INTERVAL_BAR_2 = "INTERVAL_BAR_2",
  INTERVAL_BAR_4 = "INTERVAL_BAR_4",
}

/* Generic Directions */

export const enum DirectionType {
  UP = "UP",
  DOWN = "DOWN",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  UP_LEFT = "UP_LEFT",
  UP_RIGHT = "UP_RIGHT",
  DOWN_LEFT = "DOWN_LEFT",
  DOWN_RIGHT = "DOWN_RIGHT",
  IDLE = "IDLE",
}

export type AnimationDirectionIndexMap = {
  [key in DirectionType]: { key: string; angle: number };
};

/* Base Ability Types */

export enum AbilityType {
  CHANNEL = "CHANNEL",
  INSTANT = "INSTANT",
}

export interface AbilityMetronomeIntervals {
  windup: MetronomeIntervals; // what interval the ability should start
  action: MetronomeIntervals; // what interval the ability should action
}

/*  */

/*  */

/*  */

/*  */

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//    Constants
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export default abstract class Constants {
  /* Debugging */
  static readonly DEBUG_KEY: string = "T";
  static readonly ENABLE_ABILITY_LOGS: boolean = true;
  static readonly ENABLE_BOSS_LOGS: boolean = true;

  /* Emit Keys */
  static readonly ABILITY_ACTIONED: string = "ability-actioned";
  static readonly SPRITE_NO_MORE_ABILITIES: string = "no-more-abilities";

  /* Game */
  static readonly GAME_WIDTH: number = 2560;
  static readonly GAME_HEIGHT: number = 1240;
  static readonly DEFAULT_ANIMATION_FRAME_RATE: number = 12; // based on what James uses within Aseprite
  static readonly DEBUG_Z: number = 1000; // depth (z-axis) of debugging features, i.e. the rendering order of objects, this being 1000
  static readonly HEART_Z: number = 10; // depth of heart
  static readonly PLAYER_Z: number = 9; // etc.
  static readonly ORGAN_SHOOTER_Z: number = 5; // organ shooter projectiles are this depth + 1
  static readonly TUBA_DWARF_Z: number = 0;

  /* Scene Keys */
  static readonly BOOT_SCENE_KEY: string = "BootScene";
  static readonly TITLE_SCENE_KEY: string = "TitleScene";
  static readonly LOADING_SCENE_KEY: string = "LoadingScene";
  static readonly DEBUG_SCENE_KEY: string = "DebugScene";
  static readonly UI_SCENE_KEY: string = "UIScene";
  static readonly TUTORIAL_SCENE_KEY: string = "TutorialScene";

  /* Player */
  static readonly PLAYER_NAME: string = "player";
  static readonly PLAYER_SPEED: number = 400;
  static readonly PLAYER_DASH_SPEED: number = 1250;
  static readonly PLAYER_DASH_DURATION_MS: number = 100; // Make it shorter so player can continue doing STUFF
  static readonly PLAYER_DASH_COOLDOWN_MS: number = 500;
  static readonly PLAYER_ATTACK_DURATION_MS: number = 300;

  /* Music + Tempo */
  static readonly RATE: number = 1; // not sure what RATE should actually do, figure out later TODO
  static readonly TEMPO: number = 80; // 80 BPM

  /* Heart */
  static readonly HEART_ZONE_GOOD: string = "GOOD";
  static readonly HEART_ZONE_GREAT: string = "GREAT";
  static readonly HEART_ZONE_GOOD_WIDTH: number = 50;
  static readonly HEART_ZONE_GREAT_WIDTH: number = 50; // Heart Size ~ 100pixel

  /* Heartbeat */
  static readonly HEARTBEAT_AMOUNT: number = 10;
  static readonly HEARTBEAT_VELOCITY_FACTOR: number = 4; // i.e. amount of heartbeats visible before it reaches the center - based on interval, quarter-bar == 4, half-bar == 2
  static readonly HEARTBEAT_SPAWN_OFFSET: number = -50; // -50 - TODO MATT - make consistent based on image
  static readonly HEARTBEAT_FADE_IN_TWEEN_DURATION_MS: number = 400;

  /* Animations */
  static readonly ORGAN_SHOOTER_TOTAL_FRAMES: number = Object.keys(OrganShooterJSON.frames).length;
  static readonly ORGAN_SHOOTER_SYNC_FRAME: number = 5;

  /* Metronome */
  static readonly SHORTEST_METRONOME_INTERVAL = MetronomeIntervals.INTERVAL_BAR_025;
}

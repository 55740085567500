//

import BasePhysicsSprite from "../sprites/base/BasePhysicsSprite";
import BaseSprite from "../sprites/base/BaseSprite";

//

/**
 * Simple custom class for handling removing / killing / stopping tweens
 */
export class CustomTweenManager {
  private _scene: Phaser.Scene;
  private _sprite: BasePhysicsSprite | BaseSprite;
  private _spriteTweenList: Phaser.Tweens.Tween[] = []; // the list of tweens for the given SPRITE - not entire SCENE

  constructor(scene: Phaser.Scene, sprite: BasePhysicsSprite | BaseSprite) {
    this._scene = scene;
    this._sprite = sprite;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Getters + Setters
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public get scene(): Phaser.Scene {
    return this._scene;
  }

  public get sprite(): BasePhysicsSprite | BaseSprite {
    return this._sprite;
  }

  public get spriteTweenList(): Phaser.Tweens.Tween[] {
    return this._spriteTweenList;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Functions
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Adds a tween to the Scene Tween Manager
   * Do this because then we can invoke the onComplete() method and kill the tween after adding to array etc.
   */
  public addTween(
    tweenConfig: object | Phaser.Types.Tweens.TweenBuilderConfig
  ): Phaser.Tweens.Tween {
    const tween = this.scene.tweens.add(tweenConfig); // add the tween to the scene tween manager
    this._spriteTweenList.push(tween);
    tween.once("complete", () => {
      // const target = tween.targets[0] as BasePhysicsSprite | BaseSprite; // just take first target to log
      // console.warn(`[${target?.name}, ...] Tween Complete`);
      this.killSpriteTween(tween);
    });
    return tween; // return the tween for any additional stuff
  }

  /**
   * Finds specific tween related to the sprite, and kills it
   * @param tween
   */
  public killSpriteTween(tween: Phaser.Tweens.Tween) {
    const isSpriteTarget = tween.hasTarget(this._sprite); // verify tween is targeting this sprite
    const doesSpriteExist = this._spriteTweenList.findIndex((t) => t === tween); // verify tween also exists in tween list - to then be removed as a reference

    if (isSpriteTarget && doesSpriteExist !== -1) {
      this._spriteTweenList.splice(doesSpriteExist, 1);
      tween.remove();
    }
  }

  public killAllSpriteTweens() {
    if (!this._spriteTweenList.length) {
      console.warn("No Tweens to Kill");
      return;
    }
    this._spriteTweenList.forEach((o) => {
      o.targets.forEach((o2: any) =>
        console.log(`%cKILL TWEEN ON ${o2?.name}`, "background-color: DarkBlue;")
      );
      o.remove();
    });
    this._spriteTweenList = []; // remove reference to tween(s) - as stated we should do in the docs
  }

  public pauseAllSpriteTweens() {
    if (!this._spriteTweenList.length) {
      console.warn("No Tweens to Stop");
      return;
    }
    this._spriteTweenList.forEach((o) => o?.pause());
  }

  public resumeAllSpriteTweens() {
    if (!this._spriteTweenList.length) {
      console.warn("No Tweens to Resume");
      return;
    }
    this._spriteTweenList.forEach((o) => o?.resume());
  }
}

import Constants, { MetronomeIntervals } from "../../Constants";
import StateMachine, { State } from "../../states/StateMachine";
import Boss, { BossStates } from "./Boss";

/**
 * /////////////////////////////////////////////////////////////////////////////////////////////////////////
 *
 * Boss Idle State
 *
 * Idle can transition to:
 *  - ...
 *
 */
export class IdleState implements State<Boss> {
  stateMachine: StateMachine<Boss>;

  constructor(stateMachine: StateMachine<Boss>) {
    this.stateMachine = stateMachine;
  }

  enter(scene: Phaser.Scene, boss: Boss) {
    if (Constants.ENABLE_BOSS_LOGS) console.log(" > boss entering idle...");

    // listen for metronome event to then transition to the THINK phase
    boss.metronome.once(MetronomeIntervals.INTERVAL_BAR_1, () => {
      this.stateMachine.transition(BossStates.THINK);
    });

    boss.playGenericAnimation("Priest_Idle");

    // metronomeIntervalExtender(
    //   "IDLE_TO_THINK",
    //   boss.getMetronome,
    //   MetronomeIntervals.INTERVAL_BAR_1,
    //   () => {
    //     this.stateMachine.transition(BossStates.THINK);
    //   },
    //   this
    //   // true,
    //   // true
    // );
  }

  update() {
    //
  }

  exit() {
    if (Constants.ENABLE_BOSS_LOGS) console.log(" < boss exiting idle...");
  }
}

/**
 * /////////////////////////////////////////////////////////////////////////////////////////////////////////
 *
 * Boss Think State
 *
 * This is where the boss will think of actions to do, based on probability / weights
 * The idea is to construct a ordered list / array of some sort, which contains all the actions that will be actioned out once in the action State
 * The construction will be based on a probability, which will inherently supports chaining abilities
 *
 * Think can transition to:
 *  - ...
 *
 */
export class ThinkState implements State<Boss> {
  stateMachine: StateMachine<Boss>;

  constructor(stateMachine: StateMachine<Boss>) {
    this.stateMachine = stateMachine;
  }

  async enter(scene: Phaser.Scene, boss: Boss) {
    if (Constants.ENABLE_BOSS_LOGS) console.log(" > boss entering think...");

    // TODO make this an actual thing later to choose between various animations of the same type
    boss.playGenericAnimation(`Priest_Think_${Phaser.Math.Between(0, 1)}`, 0);

    const questionMark = scene.add
      .image(boss.x, boss.y - 200, "question_mark")
      .setScale(0.25)
      .setDepth(boss.depth + 1);

    // the actual thinking logic that decides what abilities the boss should use - keep this separate from any remaining cost calculations etc. for code cleanliness
    await boss.thinkingLogic();

    // console.log(JSON.stringify(boss.abilityGraph.getAllValues(), null, 2));

    // listener event to come after await boss.thinkingLogic()
    boss.metronome.once(MetronomeIntervals.INTERVAL_BAR_1, () => {
      questionMark.destroy();
      this.stateMachine.transition(BossStates.ACTION);
    });

    // metronomeIntervalExtender(
    //   "THINK_TO_ACTION",
    //   boss.getMetronome,
    //   MetronomeIntervals.INTERVAL_BAR_1,
    //   () => {
    //     questionMark.destroy();
    //     this.stateMachine.transition(BossStates.ACTION);
    //   },
    //   this
    // );
  }

  update() {
    //
  }

  exit() {
    if (Constants.ENABLE_BOSS_LOGS) console.log(" < boss exiting think...");
  }
}

/**
 * /////////////////////////////////////////////////////////////////////////////////////////////////////////
 *
 * Boss Action State
 *
 * Action can transition to:
 *  - ...
 *
 */
export class ActionState implements State<Boss> {
  stateMachine: StateMachine<Boss>;

  constructor(stateMachine: StateMachine<Boss>) {
    this.stateMachine = stateMachine;
  }

  enter(scene: Phaser.Scene, boss: Boss) {
    if (Constants.ENABLE_BOSS_LOGS) console.log(" > boss entering action...");

    // after no more abilities return back to idle
    boss.once(Constants.SPRITE_NO_MORE_ABILITIES, () => {
      // boss.getMetronome.once(MetronomeIntervals.INTERVAL_BAR_1, () => {
      this.stateMachine.transition(BossStates.IDLE);
      // });
    });

    // action out abilities in order, and clear the array
    boss.abilityGraph.executeAbilitiesToUse();
  }

  update() {
    //
  }

  exit() {
    if (Constants.ENABLE_BOSS_LOGS) console.log(" < boss exiting action...");
  }
}

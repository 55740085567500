import Metronome from "../../metronome/Metronome";
import { CustomTweenManager } from "../../tween/CustomTweenManager";
import BaseSpriteMixin, { ISprite } from "./BaseSpriteMixins";

/**
 * The absolute starting point for a Physics Sprite
 * Capable of handling collisions etc.
 */
export default class BasePhysicsSprite extends Phaser.Physics.Arcade.Sprite implements ISprite {
  private _metronome: Metronome;
  private _collisionSprites: BasePhysicsSprite[];
  private _spriteTweenManager: CustomTweenManager;

  // testing
  private _hasCollided = false;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    metronome: Metronome, // every sprite will have a the metronome instance
    name: string,
    // optional
    collideWorldBounds = true,
    collisionSprites: BasePhysicsSprite[] = []
  ) {
    super(scene, x, y, texture);

    this._metronome = metronome;
    this.setName(name); // Arcade Sprite name

    // collision logic
    this._collisionSprites = collisionSprites;
    // if (collisionSprites.length) this.initSpriteCollisionLogic(); // if populated, run collision logic

    this._spriteTweenManager = new CustomTweenManager(scene, this);

    scene.physics.add.existing(this); // physics sprite, so add to physics world
    this.setCollideWorldBounds(collideWorldBounds);

    scene.add.existing(this);
    scene.anims.createFromAseprite(texture); // every actor will have as aseprite sheet to animate from, so create one for each instance
    // this.setDrag(10);
    // console.log("BasePhysicsSprite");
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Getters + Setters
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public get metronome(): Metronome {
    return this._metronome;
  }

  public get collisionSprites(): BasePhysicsSprite[] {
    return this._collisionSprites;
  }

  public set collisionSprites(v: BasePhysicsSprite[]) {
    this._collisionSprites = v;
  }

  public get spriteTweenManager(): CustomTweenManager {
    return this._spriteTweenManager;
  }

  public get hasCollided(): boolean {
    return this._hasCollided;
  }

  public set hasCollided(v: boolean) {
    this._hasCollided = v;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Functions
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Because we usually need to create all the sprites first in the main game scene, we can only initialise collisions after all sprites are initialised
   * Able to pass an optional reference to a new list of collision sprites, if one wasn't pass through when constructing this sprite
   */
  public initSpriteCollisionLogic(newCollisionSprites?: BasePhysicsSprite[]) {
    if (newCollisionSprites?.length) this.collisionSprites = newCollisionSprites; // using setter method
    this.collisionLogic(); // can be overridden with custom logic
  }

  /**
   * Call the playGenericAnimationMixin method - this method is probably overkill in hindsight
   */
  public playGenericAnimation(
    animation: string,
    repeat?: number,
    frameRate?: number,
    ignoreIfPlaying?: boolean,
    playReverse?: boolean
  ) {
    BaseSpriteMixin.prototype.playGenericAnimationMixin(
      this,
      animation,
      repeat,
      frameRate,
      ignoreIfPlaying,
      playReverse
    );
  }

  /**
   * Handle destroying sprite in a clean way
   */
  public kill() {
    // add some additional logic if needed
    console.log(`%cKILL SPRITE ${this.name} ...`, "background-color: DarkRed;");
    this.stop();
    this.spriteTweenManager.killAllSpriteTweens();
    this.destroy(); // finally remove sprite for garbage collection
  }

  /**
   * Handle generic collision logic against the collision sprites param
   */
  public collisionLogic() {
    console.log("COLLIDER", this.name, this._collisionSprites);
    this.scene.physics.add.collider(this, this._collisionSprites, () => {
      this.hasCollided = true;
      // additional generic logic
    });
  }
}
